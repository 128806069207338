import React from 'react';
import { motion } from 'framer-motion';

// Animation
const top = {
  closed: {
    rotate: 0,
    translateY: 0,
    transition: {
      duration: 0.2,
      ease: 'easeOut',
    },
  },
  opened: {
    rotate: 45,
    translateY: 2,
    transition: {
      delay: 0.2,
      duration: 0.2,
      ease: 'easeOut',
    },
  },
};

const center = {
  closed: {
    opacity: 1,
    transition: {
      delay: 0.2,
      duration: 0.2,
      ease: 'easeOut',
    },
  },
  opened: {
    opacity: 0,
    transition: {
      duration: 0.2,
      ease: 'easeOut',
    },
  },
};

const bottom = {
  closed: {
    rotate: 0,
    translateY: 0,
    transition: {
      duration: 0.2,
      ease: 'easeOut',
    },
  },
  opened: {
    rotate: -45,
    translateY: -2,
    transition: {
      delay: 0.2,
      duration: 0.2,
      ease: 'easeOut',
    },
  },
};

export const MenuToggle = ({ navOpen, width = 27, height = 19, ...props }) => {
  const variant = navOpen ? 'opened' : 'closed';
  const unitHeight = 6;
  const unitWidth = (unitHeight * width) / height;
  return (
    <div {...props}>
      <motion.svg
        viewBox={`0 0 ${unitWidth} ${unitHeight}`}
        overflow='visible'
        preserveAspectRatio='none'
        width={width}
        height={height}
        strokeWidth='2.5'
        className='stroke-current'
      >
        <motion.line
          x1='0'
          x2={unitWidth}
          y1='0'
          y2='0'
          variants={top}
          animate={variant}
          initial='closed'
          vectorEffect='non-scaling-stroke'
        />
        <motion.line
          x1='0'
          x2={unitWidth}
          y1='2'
          y2='2'
          variants={center}
          animate={variant}
          initial='closed'
          vectorEffect='non-scaling-stroke'
        />
        <motion.line
          x1='0'
          x2={unitWidth}
          y1='4'
          y2='4'
          variants={bottom}
          animate={variant}
          initial='closed'
          vectorEffect='non-scaling-stroke'
        />
      </motion.svg>
    </div>
  );
};
