import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

// Hooks
import { usePadTop, usePadBottom } from '../../hooks';

//Variants
const aniVars = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

export const BlockWrapper = ({
  children,
  className,
  paddingTop,
  paddingBottom,
  slug,
  rest,
}) => {
  const padTop = usePadTop(paddingTop);
  const padBottom = usePadBottom(paddingBottom);

  const controls = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.25, triggerOnce: true });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <section
      className={`${className} ${padTop} ${padBottom}`}
      id={slug && slug.current}
      {...rest}
    >
      {children}
    </section>
  );
};
