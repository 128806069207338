import React, { useState, useEffect, useCallback } from 'react';
import { useEmblaCarousel } from 'embla-carousel/react';
import { setupWheelGestures } from 'embla-carousel-wheel-gestures';

// Components
import ArrowL from '../../svg/arrow-l.svg';
import ArrowR from '../../svg/arrow-r.svg';
const NavigationCarousel = ({
  useArrows,
  useNav,
  heading,
  children,
  className,
}) => {
  const [scrollProgress, setScrollProgress] = useState(0);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    loop: 'true',
  });

  const onScroll = useCallback(() => {
    if (!emblaApi) return;
    setScrollProgress(emblaApi.scrollProgress() * 100);
  }, [emblaApi]);

  useEffect(() => {
    if (emblaApi) {
      // Embla API is ready
      emblaApi.on('scroll', onScroll);
      setupWheelGestures(emblaApi);
    }
  }, [emblaApi, onScroll]);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const scrollTo = useCallback(
    (index) => emblaApi && emblaApi.scrollTo(index),
    [emblaApi],
  );

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    setScrollSnaps(emblaApi.scrollSnapList());
    emblaApi.on('select', onSelect);
  }, [emblaApi, setScrollSnaps, onSelect]);

  return (
    <>
      <div
        scrollProgress={scrollProgress}
        className={`${className} px-gutter flex flex-nowrap justify-between items-center`}
      >
        <h4 className='w-full text-4xl font-bold'>{heading && heading}</h4>
        {useArrows && (
          <nav className='flex flex-nowrap'>
            <ArrowL onClick={() => emblaApi.scrollPrev()} className='mr-4' />
            <ArrowR onClick={() => emblaApi.scrollNext()} />
          </nav>
        )}
      </div>
      <div className={`${className} overflow-x-hidden`} ref={emblaRef}>
        <div className='canvas flex'>{children}</div>
      </div>
      {useNav && (
        <nav className='mt-4 flex justify-center items-center'>
          {scrollSnaps.map((_, index) => (
            <button
              aria-label={`Slide carousel to slide ${index + 1}`}
              className={`transition-all duration-200 rounded-full mx-2 ${
                index === selectedIndex
                  ? 'bg-purple w-4 h-4'
                  : 'bg-gray-300 w-2 h-2'
              }`}
              key={index}
              selected={index === selectedIndex}
              onClick={() => scrollTo(index)}
            />
          ))}
        </nav>
      )}
    </>
  );
};

export default NavigationCarousel;
