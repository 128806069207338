import { graphql, useStaticQuery } from 'gatsby';

import { QueryFragments } from './queryFragments'; // eslint-disable-line

export const useSiteConfig = () => {
  const { sanitySiteConfig } = useStaticQuery(graphql`
    query {
      sanitySiteConfig {
        mainNav {
          ... on SanityPageLink {
            _type
            ...PageLinkFields
          }
          ... on SanityPathLink {
            _type
            ...PathLinkFields
          }
        }
      }
    }
  `);
  return sanitySiteConfig || {};
};
