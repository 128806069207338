import React from 'react';
import { useSiteConfig } from '../../GraphQl/useSiteConfig';
import { NavLink } from './navLink';

export const DeskNav = ({ className }) => {
  const { mainNav } = useSiteConfig();
  return (
    <nav
      className={`${className} uppercase semibold text-sm tracking-wider flex items-center`}
    >
      {mainNav.map((item, index) => (
        <>
          <NavLink className='ml-6 lg:ml-8 transition duration-300' {...item} />
          {index + 1 === mainNav.length && (
            <div
              className={`align-text-bottom ml-2 inline-block w-4 h-4 rounded-full bg-purple`}
            ></div>
          )}
        </>
      ))}
    </nav>
  );
};
