import { graphql, useStaticQuery } from 'gatsby';

import { QueryFragments } from './queryFragments'; // eslint-disable-line

export const useSiteFooter = () => {
  const { sanitySiteFooter } = useStaticQuery(graphql`
    query {
      sanitySiteFooter {
        heading
        disclaimer
        linkedIn
        facebook
        _rawContactDetails(resolveReferences: { maxDepth: 10 })
        _rawAddress(resolveReferences: { maxDepth: 10 })
        footerNav {
          ... on SanityPageLink {
            _type
            ...PageLinkFields
          }
          ... on SanityPathLink {
            _type
            ...PathLinkFields
          }
        }
      }
    }
  `);
  return sanitySiteFooter || {};
};
