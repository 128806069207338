import React from 'react';

import { BlockWrapper } from '../blockWrapper';
import { PortableTextBlock } from '../../sanity/portableTextBlock';

const TextBlock = ({ _rawText, _rawTextTwo, centerText, blockConfig }) => {
  return (
    <BlockWrapper
      className={`px-gutter xl:px-0 prose lg:prose-xl mx-auto ${
        centerText ? 'text-center' : ''
      } ${_rawText && _rawTextTwo ? 'max-w-5xl' : 'max-w-4xl'}`}
      {...blockConfig}
    >
      {_rawText && !_rawTextTwo && (
        <>
          <PortableTextBlock className='md:pr-2' text={_rawText} />
        </>
      )}
      {_rawText && _rawTextTwo && (
        <article className='grid grid-cols-1 md:grid-cols-2 grid-row-flow gap-2 md:gap-4 lg:gap-8'>
          <PortableTextBlock className='col-span-1' text={_rawText} />
          <PortableTextBlock className='col-span-1' text={_rawTextTwo} />
        </article>
      )}
    </BlockWrapper>
  );
};

export default TextBlock;
