import React, { createContext, useContext, useState } from 'react';

// Hooks
import { useToggle } from '../hooks';

export const AppState = createContext({
  navOpen: false,
  headerPinned: false,
  globalTheme: 'white',
});

export const AppWrapper = ({ children }) => {
  const [navOpen, setNavOpen, toggleNav] = useToggle(false);

  const [globalTheme, setGlobalTheme] = useState(null);

  return (
    <AppState.Provider
      value={{
        navOpen,
        setNavOpen,
        toggleNav,
        globalTheme,
        setGlobalTheme,
      }}
    >
      {children}
    </AppState.Provider>
  );
};

export const useAppState = () => useContext(AppState);
