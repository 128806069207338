import React from 'react';
import Image from 'gatsby-plugin-sanity-image';

// Components
import { BlockWrapper } from '../blockWrapper';
import { PortableTextBlock } from '../../sanity/portableTextBlock';
import { HoverButton } from '../../ui';

const BioItem = ({ name, role, _rawSummary, emailAddress, image }) => {
  let nameArray = name.split(' ');

  return (
    <article className='col-span-1'>
      <div className='w-full relative aspect-w-5 aspect-h-5 mb-12'>
        <Image className='absolute inset-0 object-cover' {...image} />
      </div>
      {role && (
        <h6 className='font-bold text-base text-purple uppercase tracking-wider'>
          {role}
        </h6>
      )}
      {name && <h4 className='font-bold text-3xl lg:text-4xl mb-4'>{name}</h4>}
      {_rawSummary && (
        <PortableTextBlock className='prose lg:prose-xl' text={_rawSummary} />
      )}
      {emailAddress && (
        <HoverButton className='mt-4'>
          <a href={`mailto:` + emailAddress}>Contact {nameArray[0]}</a>
        </HoverButton>
      )}
    </article>
  );
};
const PeopleGrid = ({ blockConfig, title, items }) => {
  return (
    <BlockWrapper className={`px-gutter`} {...blockConfig}>
      {title && (
        <h2 className='text-4xl lg:text-5xl font-bold text-center mb-12 lg:mb-28'>
          {title}
        </h2>
      )}
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 grid-row-flow'>
        {items.map((item) => (
          <BioItem {...item} />
        ))}
      </div>
    </BlockWrapper>
  );
};

export default PeopleGrid;
