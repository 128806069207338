import React from 'react';
import { BlockWrapper } from '../blockWrapper';

const IFrameBlock = ({ iFrameUrl, title, blockConfig }) => {
  return (
    <BlockWrapper {...blockConfig} className=''>
      <div className='componentWrapper  ' style={{ height: '900px' }}>
        <iFrame
          className='iframeContainer'
          src={iFrameUrl}
          style={{
            height: '100%',
            maxWidth: '1600px',
            width: '100%',
            margin: 'auto',
            transform: 'translateY(-20px)',
          }}
        ></iFrame>
      </div>
    </BlockWrapper>
  );
};

export default IFrameBlock;
