import React from 'react';
import { useSiteContact } from '../../../GraphQl/useSiteContact';

// Components
import { Contact } from '../../forms/contact';

const ContactForm = () => {
  const {
    heading,
    subHeading,
    image,
    caption,
    _rawSuccessText,
  } = useSiteContact();

  let footerHeading = heading.split(' ');

  return (
    <div className='bg-black grid grid-cols-14'>
      <div className='col-start-1 col-end-15 row-start-1 z-0 relative'>
        <div
          className='bg-bottom bg-cover h-full md:h-3/4'
          style={{ backgroundImage: 'url(' + image.asset.url + ')' }}
        ></div>
      </div>
      <div className='col-start-2 col-end-14 md:col-start-6 row-start-1 z-20 md:flex justify-end'>
        <div
          style={{ maxWidth: '620px' }}
          className='w-full mt-24 lg:mt-40 2xl:mt-64 bg-purple rounded-lg text-white text-center px-6 md:px-8 lg:px-12 py-gutter xl:p-20 relative'
        >
          <h2 className='font-bold text-2xl md:text-4xl mb-4'>{subHeading}</h2>
          <h5 className='text-2xl md:text-2xl mb-4'>{caption}</h5>
          <Contact classname='' _rawSuccessText={_rawSuccessText} />
        </div>
      </div>
      <div className='col-start-2 col-end-14 md:col-end-5 row-start-2 md:row-start-1 z-10 pt-12 pb-2 md:flex items-end justify-start'>
        <h5 className='semibold text-purple text-3xl lg:text-5xl text-center md:text-left'>
          {footerHeading[0]}
          <br />
          {footerHeading[1]} {footerHeading[2]}
        </h5>
      </div>
    </div>
  );
};

export default ContactForm;
