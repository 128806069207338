import { useState } from 'react';
// import { globalHistory } from "@reach/router"

// function encode(data) {
//   return Object.keys(data)
//     .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
//     .join("&")
// }

function useFormValidation(initialState) {
  const [values, setValues] = useState(initialState);
  const [success, setSuccess] = useState(false);
  // const [formError, setFormError] = useState(null)
  const [formError] = useState(null);
  // const [captchaRes, setCaptchaRes] = useState(null)
  // const path = globalHistory.location.pathname

  // function handleCaptchaChange(e) {
  //   setFormError(null)
  //   setCaptchaRes(e)
  // }
  //--
  function handleChange(event) {
    event.persist();
    setValues((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }));
  }
  //--
  function handleSubmit(event) {
    event.preventDefault();
    setSuccess(true);
    // const form = event.target
    // const formName = form.getAttribute("name").toString()
    // fetch(path, {
    //   method: "POST",
    //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
    //   body: encode({
    //     "form-name": `${formName}`,
    //     ...values,
    //   }),
    // })
    // .then(() => setSuccess(true))
    // .catch((error) => alert(error));
    // if (captchaRes) {
    //   fetch(path, {
    //     method: "POST",
    //     headers: { "Content-Type": "application/x-www-form-urlencoded" },
    //     body: encode({
    //       "form-name": `${formName}`,
    //       ...values,
    //     }),
    //   })
    //     .then(() => setSuccess(true))
    //     .catch(error => alert(error))
    // } else {
    //   setFormError("Please complete the Captcha")
    // }
  }

  return {
    handleChange,
    handleSubmit,
    values,
    success,
    formError,
  };
}

export default useFormValidation;
