import React from 'react';
import { useSiteFooter } from '../../GraphQl/useSiteFooter';
import { PortableTextBlock } from '../sanity/portableTextBlock';
import { NavLink } from './navLink';

import LinkedIn from '../../svg/linkedin.svg';

export const Footer = () => {
  const {
    heading,
    disclaimer,
    linkedIn,
    facebook,
    _rawContactDetails,
    _rawAddress,
    footerNav,
  } = useSiteFooter();
  return (
    <footer className='bg-footer text-sm text-white px-gutter py-12 lg:py-32 grid gap-6 md:gap-x-0 grid-col-1 md:grid-cols-3 lg:grid-cols-12 row-auto-flow text-center md:text-left'>
      <div className='col-span-1 md:col-span-3 lg:col-span-12'>
        <span className='block text-base lg:text-lg font-bold'>{heading}</span>
      </div>
      <div className='col-span-1 lg:col-span-2'>
        {_rawAddress && <PortableTextBlock text={_rawAddress} />}
      </div>
      <div className='col-span-1 lg:col-span-2'>
        {_rawContactDetails && (
          <>
            <strong>Contact</strong>
            <PortableTextBlock text={_rawContactDetails} />
          </>
        )}
      </div>
      <div className='col-span-1 lg:col-span-2'>
        <strong>Follow</strong>
        <br />
        {linkedIn && (
          <a
            href={`${linkedIn}`}
            target='_blank'
            rel='noreferrer'
            className='flex flex-wrap justify-center md:justify-start items-center underline'
          >
            <LinkedIn className='mr-2' />
            LinkedIn
          </a>
        )}
        {facebook && (
          <a
            href={`${facebook}`}
            target='_blank'
            rel='noreferrer'
            className='block underline'
          >
            Facebook
          </a>
        )}
      </div>
      <div className='text-xs col-span-1 md:col-span-3 lg:text-right lg:col-span-6 lg:flex flex-col items-end justify-end'>
        {footerNav.map((item, index) => (
          <>
            <NavLink className='block underline mb-2' {...item} />
          </>
        ))}
        <p>©2021 Influx Energy Data Limited. All rights reserved.</p>
      </div>
      {disclaimer && (
        <div className='col-span-1 md:col-span-3 lg:col-span-12 text-xs text-center opacity-75 max-w-5xl mx-auto pt-6'>
          {disclaimer}
        </div>
      )}
    </footer>
  );
};
