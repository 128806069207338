import React from 'react';
import Image from 'gatsby-plugin-sanity-image';
import { Link } from 'gatsby';
// GQL Fragments
import { useLatestArticles } from '../../../GraphQl/useLatestArticles';

// Components
import { HoverButton } from '../../ui';
import { NavigationCarousel } from '../../carousels';

const ArticleCard = ({ className, item }) => {
  const { meta, date, title, summary, slug } = item;
  return (
    <Link to={`/${slug && slug.current}`} className={`${className}`}>
      <div className='relative rounded-md overflow-hidden aspect-w-3 aspect-h-2 border border-gray-100'>
        {meta && (
          <Image
            className='absolute w-ful h-full object-cover'
            {...meta.metaImage}
          />
        )}
      </div>
      <p className='font-bold uppercase mt-6 tracking-wide'>{date}</p>
      <h4 className='text-2xl xl:text-3xl font-bold my-4'>{title}</h4>
      <p className='text-lg mb-4'>{summary}</p>
      <HoverButton>Read More</HoverButton>
    </Link>
  );
};

const LatestArticles = () => {
  const articles = useLatestArticles();

  return (
    <div className='py-20 md:py-40 xl:py-52 grid grid-cols-1 grid-flow-row gap-12 text-dark'>
      {/* Desk */}
      <h4 className='hidden lg:block col-span-1 text-4xl font-bold px-gutter'>
        Latest News
      </h4>
      <div className='hidden px-gutter lg:grid grid-cols-1 lg:grid-cols-3 grid-flow-row gap-8 xl:gap-12'>
        {articles.map((item) => (
          <ArticleCard className='col-span-1' item={item.node} />
        ))}
      </div>
      {/* Mobile */}
      <NavigationCarousel
        useArrows={true}
        heading='Latest News'
        className='col-span-1 md:pl-gutter lg:hidden'
      >
        {articles?.map((item) => (
          <ArticleCard
            className='flex-slide-item w-full md:w-2/3 px-gutter md:pl-0 md:pr-12'
            item={item.node}
          />
        ))}
      </NavigationCarousel>
    </div>
  );
};

export default LatestArticles;
