import { graphql, useStaticQuery } from 'gatsby';

export const useSiteContact = () => {
  const { sanitySiteContact } = useStaticQuery(graphql`
    query {
      sanitySiteContact {
        heading
        subHeading
        image {
          asset {
            url
          }
        }
        _rawSuccessText(resolveReferences: { maxDepth: 10 })
        caption
      }
    }
  `);
  return sanitySiteContact || {};
};
