import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useLocation } from '@reach/router';
import Headroom from 'react-headroom';
import { Link } from 'gatsby';

import { useAppState } from '../../state/appState';

import { MenuToggle } from './menuToggle';
import { DeskNav } from './deskNav';
import { MobileNav } from './mobileNav';

import { Logo } from '../ui';

// Animation Variants
const headerVars = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.5,
      delay: 0.4,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
      delay: 1,
    },
  },
};

export const Header = () => {
  const { pathname } = useLocation();

  const { globalTheme, navOpen, toggleNav } = useAppState();

  const [headerPinned, setHeaderPinned] = useState(false);

  let themeClasses = '';
  if (globalTheme === 'white') {
    themeClasses = 'text-dark';
  } else if (globalTheme === 'light') {
    themeClasses = 'text-dark';
  }
  return (
    <AnimatePresence>
      <Headroom
        style={{ zIndex: '2001' }}
        onPin={() => setHeaderPinned(true)}
        onUnpin={() => setHeaderPinned(false)}
        onUnfix={() => setHeaderPinned(false)}
      >
        <motion.header
          variants={headerVars}
          initial='hidden'
          animate='visible'
          exit='hidden'
          className={`${
            pathname !== '/' && !navOpen && themeClasses
          } transition-all duration-200 flex justify-between items-center w-full px-gutterHalf xl:px-20 py-2 md:py-4 lg:px-8 ${
            headerPinned && !navOpen ? 'bg-white text-dark' : 'bg-transparent'
          } ${(!headerPinned || navOpen) && 'text-white'} ${
            headerPinned ? 'lg:py-2' : 'lg:py-8'
          } ${headerPinned && navOpen && 'text-white'}`}
        >
          <Link
            to={`/`}
            onClick={() => navOpen(false)}
            className='text-current'
          >
            <Logo
              className={`w-32 transition duration-100 ${
                headerPinned ? 'md:w-28 lg:w-32' : 'md:w-40 lg:w-52'
              }`}
            />
          </Link>
          <DeskNav className='hidden md:block' />
          <MenuToggle
            navOpen={navOpen}
            className={`md:hidden`}
            onClick={toggleNav}
          />
        </motion.header>
      </Headroom>
      <MobileNav className='md:hidden' />
    </AnimatePresence>
  );
};
