import React from 'react';
import Image from 'gatsby-plugin-sanity-image';

// Components
import { BlockWrapper } from '../blockWrapper';

const GridItem = ({ text, title, icon }) => {
  return (
    <article className='col-span-1 text-center'>
      {icon && (
        <Image
          style={{ maxWidth: '120px' }}
          className='w-1/2 mx-auto object-contain mb-8'
          {...icon}
        />
      )}
      {title && (
        <h6 className='font-bold text-2xl lg:text-3xl mb-2'>{title}</h6>
      )}
      {text && <p className='lg:text-lg'>{text}</p>}
    </article>
  );
};

const IconGrid = ({ blockConfig, items }) => {
  return (
    <BlockWrapper className={`px-gutter`} {...blockConfig}>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16 xl:gap-x-24 2xl:gap-x-40 grid-row-flow'>
        {items.map((item) => (
          <GridItem {...item} />
        ))}
      </div>
    </BlockWrapper>
  );
};

export default IconGrid;
