import React from 'react';

import { AnimatePresence, motion } from 'framer-motion';

// Hooks, Querys & State
import { useAppState } from '../state/appState';

import '../css/main.css';

import { useLocation } from '@reach/router';

import { Header } from '../components/nav/header';
import { Footer } from '../components/nav/footer';

import { ContactForm } from '../components/blockZone/blocks';

const aniVars = {
  enter: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

const Layout = ({ children }) => {
  const { globalTheme } = useAppState();

  let themeClasses = '';
  if (globalTheme === 'dark') {
    themeClasses = 'bg-dark text-white';
  } else if (globalTheme === 'light') {
    themeClasses = 'bg-light text-dark';
  } else {
    themeClasses = 'bg-white text-dark';
  }

  const { pathname } = useLocation();
  return (
    <>
      <Header />
      <AnimatePresence exitBeforeEnter>
        <motion.main
          className={`antialiased w-full absolute top-0 left-0 ${themeClasses}`}
          key={pathname}
          variants={aniVars}
          initial='exit'
          animate='enter'
          exit='exit'
          transition={{
            ease: 'easeInOut',
            duration: 0.3,
          }}
        >
          {children}
          <ContactForm />
          <Footer />
        </motion.main>
      </AnimatePresence>
    </>
  );
};

export default Layout;
