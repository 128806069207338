import React from 'react';
import Image from 'gatsby-plugin-sanity-image';
import { BlockWrapper } from '../blockWrapper';

const ImageBlock = ({ blockConfig, image, layout }) => {
  return (
    <BlockWrapper {...blockConfig} className='grid grid-cols-14'>
      <div className='col-start-2 col-end-14'>
        {image && <Image {...image} />}
      </div>
    </BlockWrapper>
  );
};

export default ImageBlock;
