import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { BlockWrapper } from '../blockWrapper';
import { PortableTextBlock } from '../../sanity/portableTextBlock';

const AccordionItem = ({ title, _rawText, item, expanded, setExpanded }) => {
  const isOpen = item === expanded;
  return (
    <div className={`w-full max-w-4xl mx-auto`}>
      <motion.header
        initial={false}
        onClick={() => setExpanded(isOpen ? false : item)}
      >
        <h5 className=' flex justify-between items-center py-4 text-g lg:text-xl font-bold tracking-wide border-b border-purple'>
          {title && <span className='w-11/12'>{title}</span>}
          <span>{isOpen ? '-' : '+'}</span>
        </h5>
      </motion.header>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            key='content'
            initial='collapsed'
            animate='open'
            exit='collapsed'
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.3, ease: [0.4, 0.7, 0.23, 0.98] }}
          >
            {_rawText && (
              <div className='py-4 prose lg:prose-xl max-w-none'>
                <PortableTextBlock text={_rawText} />
              </div>
            )}
          </motion.section>
        )}
      </AnimatePresence>
    </div>
  );
};
const Accordion = ({ title, summary, items, blockConfig }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <BlockWrapper className='px-gutter' {...blockConfig}>
      <section className='w-full max-w-3xl mx-auto text-center'>
        {title && (
          <h3 className='font-bold text-4xl lg:text-5xl mb-4'>{title}</h3>
        )}
        {summary && <p className='lg:text-xl mb-12'>{summary}</p>}
      </section>
      {items.map((item, index) => (
        <AccordionItem
          {...item}
          item={item}
          expanded={expanded}
          setExpanded={setExpanded}
        />
      ))}
    </BlockWrapper>
  );
};

export default Accordion;
