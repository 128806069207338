import React from 'react';

export const Logo = ({ className }) => {
  return (
    <svg
      className={`${className}`}
      width='215'
      height='66'
      viewBox='0 0 215 66'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M34.611 37.8605C37.9153 37.8605 40.5939 35.1976 40.5939 31.9128C40.5939 28.628 37.9153 25.9651 34.611 25.9651C31.3067 25.9651 28.6281 28.628 28.6281 31.9128C28.6281 35.1976 31.3067 37.8605 34.611 37.8605Z'
        fill='#632AFE'
      />
      <path
        d='M55.5191 33.3198C56.3363 33.3198 56.9988 32.6612 56.9988 31.8488C56.9988 31.0365 56.3363 30.3779 55.5191 30.3779C54.702 30.3779 54.0395 31.0365 54.0395 31.8488C54.0395 32.6612 54.702 33.3198 55.5191 33.3198Z'
        fill='#632AFE'
      />
      <path
        d='M62.2097 60.3721C62.7782 60.3721 63.2391 59.914 63.2391 59.3488C63.2391 58.7837 62.7782 58.3256 62.2097 58.3256C61.6413 58.3256 61.1804 58.7837 61.1804 59.3488C61.1804 59.914 61.6413 60.3721 62.2097 60.3721Z'
        fill='#632AFE'
      />
      <path
        d='M22.5165 26.0291C25.9629 26.0291 28.7567 23.2517 28.7567 19.8256C28.7567 16.3995 25.9629 13.6221 22.5165 13.6221C19.0701 13.6221 16.2762 16.3995 16.2762 19.8256C16.2762 23.2517 19.0701 26.0291 22.5165 26.0291Z'
        fill='#632AFE'
      />
      <path
        d='M45.4832 47.1337C47.9703 47.1337 49.9865 45.1294 49.9865 42.657C49.9865 40.1845 47.9703 38.1802 45.4832 38.1802C42.9961 38.1802 40.9799 40.1845 40.9799 42.657C40.9799 45.1294 42.9961 47.1337 45.4832 47.1337Z'
        fill='#632AFE'
      />
      <path
        d='M55.1331 54.8721C56.5543 54.8721 57.7064 53.7268 57.7064 52.314C57.7064 50.9011 56.5543 49.7558 55.1331 49.7558C53.7119 49.7558 52.5598 50.9011 52.5598 52.314C52.5598 53.7268 53.7119 54.8721 55.1331 54.8721Z'
        fill='#632AFE'
      />
      <path
        d='M6.8836 66C10.6853 66 13.7672 62.9363 13.7672 59.157C13.7672 55.3777 10.6853 52.314 6.8836 52.314C3.08189 52.314 0 55.3777 0 59.157C0 62.9363 3.08189 66 6.8836 66Z'
        fill='#632AFE'
      />
      <path
        d='M65.3621 2.04651C65.9306 2.04651 66.3914 1.58838 66.3914 1.02326C66.3914 0.458127 65.9306 0 65.3621 0C64.7936 0 64.3328 0.458127 64.3328 1.02326C64.3328 1.58838 64.7936 2.04651 65.3621 2.04651Z'
        fill='#632AFE'
      />
      <path
        d='M21.1012 51.1628C24.4765 51.1628 27.2128 48.4427 27.2128 45.0872C27.2128 41.7318 24.4765 39.0116 21.1012 39.0116C17.7258 39.0116 14.9896 41.7318 14.9896 45.0872C14.9896 48.4427 17.7258 51.1628 21.1012 51.1628Z'
        fill='#632AFE'
      />
      <path
        d='M45.4832 25.3256C47.9703 25.3256 49.9865 23.3213 49.9865 20.8488C49.9865 18.3764 47.9703 16.3721 45.4832 16.3721C42.9961 16.3721 40.9799 18.3764 40.9799 20.8488C40.9799 23.3213 42.9961 25.3256 45.4832 25.3256Z'
        fill='#632AFE'
      />
      <path
        d='M57.1918 11.7035C58.613 11.7035 59.7651 10.5582 59.7651 9.14536C59.7651 7.73254 58.613 6.58722 57.1918 6.58722C55.7706 6.58722 54.6185 7.73254 54.6185 9.14536C54.6185 10.5582 55.7706 11.7035 57.1918 11.7035Z'
        fill='#632AFE'
      />
      <path
        d='M34.4824 56.1512C35.9036 56.1512 37.0557 55.0058 37.0557 53.593C37.0557 52.1802 35.9036 51.0349 34.4824 51.0349C33.0612 51.0349 31.9091 52.1802 31.9091 53.593C31.9091 55.0058 33.0612 56.1512 34.4824 56.1512Z'
        fill='#632AFE'
      />
      <path
        d='M35.3187 10.6802C36.7399 10.6802 37.892 9.53489 37.892 8.12207C37.892 6.70925 36.7399 5.56393 35.3187 5.56393C33.8975 5.56393 32.7454 6.70925 32.7454 8.12207C32.7454 9.53489 33.8975 10.6802 35.3187 10.6802Z'
        fill='#632AFE'
      />
      <path
        d='M10.3576 11.1918C11.9564 11.1918 13.2525 9.90337 13.2525 8.31394C13.2525 6.72452 11.9564 5.43604 10.3576 5.43604C8.75871 5.43604 7.46259 6.72452 7.46259 8.31394C7.46259 9.90337 8.75871 11.1918 10.3576 11.1918Z'
        fill='#632AFE'
      />
      <path
        className='fill-current'
        d='M77.1993 22.1279H84.0185L80.3516 47.8372H73.5323L77.1993 22.1279Z'
        fill='white'
      />
      <path
        className='fill-current'
        d='M105.377 32.2965C105.892 28.7151 104.155 27.5 101.26 27.5C98.5577 27.5 96.3061 28.9709 94.9551 30.5697L92.5104 47.9011H85.6912L89.3581 22.1279H96.1774L95.7271 25.5174C97.6571 23.5349 101.131 21.4883 105.377 21.4883C111.038 21.4883 113.354 24.686 112.647 29.6744L110.073 47.8372H103.254L105.377 32.2965Z'
        fill='white'
      />
      <path
        className='fill-current'
        d='M119.595 28.0116H115.284L116.121 22.064H120.431L120.624 20.657C121.396 15.0291 125.513 11.7035 130.66 11.7035C132.59 11.7035 134.327 12.1512 135.42 12.7907L133.362 17.7151C132.847 17.3314 132.139 17.0756 131.239 17.0756C129.309 17.0756 127.829 18.3547 127.507 20.7209L127.314 22.1279H132.59L131.753 28.0756H126.478L123.647 47.9012H116.764L119.595 28.0116Z'
        fill='white'
      />
      <path
        className='fill-current'
        d='M138.894 12.2791H145.714L140.696 47.8372H133.876L138.894 12.2791Z'
        fill='white'
      />
      <path
        className='fill-current'
        d='M163.984 44.6395C161.925 46.6221 158.58 48.5407 154.334 48.5407C148.673 48.5407 146.421 45.4709 147.129 40.4186L149.702 22.1279H156.521L154.27 37.7325C153.755 41.3139 155.492 42.4651 158.387 42.4651C161.025 42.4651 163.341 40.9942 164.692 39.5232L167.136 22.0639H173.956L170.289 47.8372H163.469L163.984 44.6395Z'
        fill='white'
      />
      <path
        className='fill-current'
        d='M187.273 39.1395L180.132 47.8372H172.54L183.606 34.5988L176.722 22.0639H184.313L188.559 29.9942L194.992 22.0639H202.648L192.162 34.5988L199.56 47.8372H191.969L187.273 39.1395Z'
        fill='white'
      />
      <path
        className='fill-current'
        d='M81.8956 18.4186C84.1695 18.4186 86.0128 16.5861 86.0128 14.3256C86.0128 12.0651 84.1695 10.2325 81.8956 10.2325C79.6216 10.2325 77.7783 12.0651 77.7783 14.3256C77.7783 16.5861 79.6216 18.4186 81.8956 18.4186Z'
        fill='white'
      />
    </svg>
  );
};
