export const useSanityPath = (props) => {
  const { _type, main, slug } = props || {};

  function getPath(_type, slug, main) {
    switch (_type) {
      case 'page':
        return `/${slug.current}`;
      case 'solution':
        return `/solutions/${slug.current}`;
      case 'homePage':
        return `/`;
      default:
        return null;
    }
  }

  const path = getPath(_type, slug, main);

  return path;
};
