import React from 'react';
import Image from 'gatsby-plugin-sanity-image';
import { motion } from 'framer-motion';
import { BlockWrapper } from '../blockWrapper';
const CarouselItem = ({ item }) => {
  return (
    <div className='w-32 md:w-44 lg:w-68 xl:w-72' style={{ flex: '0 0 auto' }}>
      {item && <Image {...item} className='self-center w-4/5 mx-auto' />}
    </div>
  );
};
const PartnersCarousel = ({ blockConfig, title, items }) => {
  return (
    <BlockWrapper {...blockConfig}>
      <h3 className='px-gutter text-center lg:px-8  text-xl md:text-2xl lg:text-4xl font-bold max-w-3xl mx-auto mb-12'>
        {title}
      </h3>
      <div className='overflow-hidden w-full h-20 lg:h-36 xl:h-48 relative'>
        {/* Carousel One */}
        <motion.div
          animate={{ x: ['0%', '-100%'] }}
          transition={{
            loop: Infinity,
            ease: 'linear',
            duration: 30,
            times: [0, 1],
          }}
          className='absolute top-0 flex'
        >
          {items.map((item) => (
            <CarouselItem item={item} />
          ))}
        </motion.div>
        {/* Carousel Two */}
        <motion.div
          animate={{ x: ['100%', '0%'] }}
          transition={{
            ease: 'linear',
            duration: 30,
            loop: Infinity,
            times: [0, 1],
          }}
          className='absolute top-0 flex'
        >
          {items.map((item) => (
            <CarouselItem item={item} />
          ))}
        </motion.div>
      </div>
    </BlockWrapper>
  );
};
export default PartnersCarousel;
