import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useSiteConfig } from '../../GraphQl/useSiteConfig';
import { useAppState } from '../../state/appState';
import { useScrollFreeze } from '../../hooks';
import { NavLink } from './navLink';

// Animation Variants
const navWrapper = {
  closed: {
    opacity: 0,
    transition: {
      duration: 0.5,
      delay: 0.4,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
  open: {
    opacity: 1,
    transition: {
      duration: 0.7,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
};

const NavInner = ({ toggleNav }) => {
  const { mainNav } = useSiteConfig();
  useScrollFreeze();
  return (
    <motion.div
      className='fixed h-screen w-full bg-dark flex items-center justify-center flex-col z-2000 top-0 left-0 bottom-0 text-white'
      variants={navWrapper}
      initial='closed'
      animate='open'
      exit='closed'
    >
      <nav
        className={`flex flex-col semibold items-center uppercase text-xl md:text-3xl`}
      >
        {mainNav.map((item, index) => (
          <>
            <NavLink
              className='transition duration-300 my-4'
              onClick={toggleNav}
              {...item}
            />
          </>
        ))}
      </nav>
    </motion.div>
  );
};
export const MobileNav = () => {
  const { navOpen, toggleNav } = useAppState();
  return (
    <AnimatePresence>
      {navOpen && <NavInner toggleNav={toggleNav} />}
    </AnimatePresence>
  );
};
