import React, { useState } from 'react';
import { BlockWrapper } from '../blockWrapper';
import { wrap } from '@popmotion/popcorn';

import { motion, AnimatePresence, AnimateSharedLayout } from 'framer-motion';
import ReactPlayer from 'react-player';
import { PortableTextBlock } from '../../sanity/portableTextBlock';

import { videoWrapper } from './refrenceTabs.module.css';

const Slider = ({ pageIndex, setPageIndex, items }) => {
  return (
    <div className='hidden md:block col-start-2 col-end-14 row-start-2 mt-12 lg:mt-20'>
      <section className='w-full max-w-4xl mx-auto flex justify-between rounded-full border-2 border-purple'>
        <AnimateSharedLayout>
          {items.map((item, index) => {
            const isActive = pageIndex === index;
            return (
              <div>
                <motion.button
                  key={index}
                  animate={{ opacity: isActive ? 1 : 0.5 }}
                  className={`outline-remove relative z-0 cursor-pointer ${
                    pageIndex === index && 'text-white font-bold'
                  }`}
                  onClick={() => {
                    setPageIndex([index, pageIndex > index ? 0 : 1]);
                  }}
                >
                  {isActive && (
                    <motion.div
                      className={`absolute z-0 -inset-0.5 rounded-full bg-purple`}
                      layoutId='bg'
                      initial={{ opacity: 0, borderRadius: '2rem' }}
                      animate={{ opacity: 1, borderRadius: '2rem' }}
                    />
                  )}
                  <div className='relative py-4 px-4 lg:px-6 block z-10 text-sm lg:text-base'>
                    {item.title}
                  </div>
                </motion.button>
              </div>
            );
          })}
        </AnimateSharedLayout>
      </section>
    </div>
  );
};

const tabVariants = {
  enter: (direction) => ({
    x: direction > 0 ? 400 : -400,
    opacity: 0,
  }),
  center: {
    x: 0,
    opacity: 1,
  },
  exit: (direction) => ({
    x: direction > 0 ? -400 : 400,
    opacity: 0,
  }),
};

const FeaturedTab = ({ title, _rawText, videoUrl, ...rest }) => {
  return (
    <motion.div
      variants={tabVariants}
      initial='enter'
      animate='center'
      exit='exit'
      transition={{
        duration: 0.3,
      }}
      className='col-start-2 col-end-14 xl:col-start-3 xl:col-end-13 row-start-1 grid grid-cols-1 md:grid-cols-2 md:gap-x-10'
      style={{ mixBlendMode: 'multiply' }}
      {...rest}
    >
      <div
        className='pointer-events-none self-center'
        style={{ mixBlendMode: 'multiply' }}
      >
        <ReactPlayer
          className={`pointer-events-none ${videoWrapper} object-fit`}
          width='100%'
          height='100%'
          url={videoUrl}
          playing={true}
          muted
          playsinline
        />
      </div>
      <div className='md:flex md:flex-col md:justify-center text-center md:text-left prose lg:prose-xl max-w-none'>
        <h3 className='text-4xl lg:text-5xl font-bold text-purple mt-4 md:mt-0 mb-4'>
          {title}
        </h3>
        <PortableTextBlock text={_rawText} />
      </div>
    </motion.div>
  );
};

const MobileNav = ({ items, pageIndex, setPageIndex }) => {
  return (
    <nav className='col-start-2 col-end-14 flex items-center justify-center md:hidden'>
      {items.map((item, index) => {
        const isActive = index === pageIndex;
        return (
          <button
            aria-label='Slide button'
            onClick={() => setPageIndex([index, pageIndex > index ? 0 : 1])}
            className={`rounded-full mx-1 transition-all focus:outline-none ${
              isActive ? 'bg-purple h-4 w-4' : 'bg-gray-300 h-2 w-2'
            }`}
          />
        );
      })}
    </nav>
  );
};

const ReferenceTabs = ({ items }) => {
  const [[pageIndex, direction], setPageIndex] = useState([0, 0]);

  const wrappedIndex = wrap(0, items.length, pageIndex);

  const paginate = (direction) => {
    setPageIndex([pageIndex + direction, direction]);
  };

  return (
    <AnimateSharedLayout>
      <BlockWrapper
        layoutId='height'
        className='bg-light grid grid-cols-14 py-20 md:py-40 xl:py-52 gap-y-10 overflow-hidden'
      >
        <AnimatePresence initial={false} exitBeforeEnter custom={direction}>
          <FeaturedTab
            key={pageIndex}
            {...items[wrappedIndex]}
            custom={direction}
            drag='x'
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
              if (offset.x > 200) {
                paginate(-1);
              } else if (offset.x < -200) {
                paginate(1);
              }
            }}
          />
        </AnimatePresence>
        <Slider
          items={items}
          pageIndex={wrappedIndex}
          setPageIndex={setPageIndex}
        />
        <MobileNav
          items={items}
          pageIndex={wrappedIndex}
          setPageIndex={setPageIndex}
        />
      </BlockWrapper>
    </AnimateSharedLayout>
  );
};
export default ReferenceTabs;
