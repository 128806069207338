import React from 'react';
import ReactPlayer from 'react-player';
import { motion } from 'framer-motion';

import { BlockWrapper } from '../blockWrapper';
import { PortableTextBlock } from '../../sanity/portableTextBlock';

const AnimationText = ({ _rawText, layout, videoUrl, blockConfig }) => {
  var imgCol = '';
  var textCol = '';
  if (layout === 'right') {
    textCol = `lg:pr-8 xl:pr-20 lg:col-start-2 lg:col-end-8`;
    imgCol = `lg:col-start-8`;
  } else {
    textCol = `lg:pl-8 xl:pl-20 lg:col-start-8`;
    imgCol = `lg:col-end-8`;
  }

  return (
    <BlockWrapper className='grid grid-cols-14 bg-light' {...blockConfig}>
      <motion.div
        className={`col-start-2 col-end-14 lg:row-start-1 self-center pointer-events-none ${imgCol}`}
        style={{ mixBlendMode: 'multiply' }}
      >
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          {videoUrl && (
            <ReactPlayer
              className='pointer-events-none'
              width='100%'
              height='auto'
              url={videoUrl}
              playing={true}
              muted
            />
          )}
        </motion.div>
      </motion.div>
      <div
        className={`prose lg:prose-xl max-w-none lg:row-start-1 col-start-2 col-end-14 self-center ${textCol}`}
      >
        {_rawText && <PortableTextBlock text={_rawText} />}
      </div>
    </BlockWrapper>
  );
};

export default AnimationText;
