import React from 'react';
import { Link } from 'gatsby';

// Hooks & Querys
import { useSanityPath } from '../../hooks';

// Components
import { HoverButton } from './';

export const PageLink = ({ page, linkText, ...rest }) => {
  const path = useSanityPath(page);
  return (
    <Link to={path} {...rest}>
      {linkText}
    </Link>
  );
};

export const PathLink = ({ path, linkText, ...rest }) => {
  return (
    <Link to={`/${path}`} {...rest}>
      {linkText}
    </Link>
  );
};

export const NavPathLink = ({ path, linkText, ...rest }) => {
  let isExternal = path.includes('https');
  return (
    <>
      {isExternal === true ? (
        <a target='_blank' rel='noreferrer' href={`${path}`} {...rest}>
          {linkText}
        </a>
      ) : (
        <Link to={`/${path}`} {...rest}>
          {linkText}
        </Link>
      )}
    </>
  );
};

export const PageButton = ({ page, linkText, ...rest }) => {
  const path = useSanityPath(page);
  return (
    <HoverButton>
      <Link to={path} {...rest}>
        {linkText}
      </Link>
    </HoverButton>
  );
};

export const PathButton = ({ path, linkText, ...rest }) => {
  return (
    <HoverButton>
      <Link to={`/${path}`} {...rest}>
        {linkText}
      </Link>
    </HoverButton>
  );
};
