import React, { useState } from 'react';
import Image from 'gatsby-plugin-sanity-image';

import { wrap } from '@popmotion/popcorn';

import { motion, AnimatePresence } from 'framer-motion';

const CarouselSlide = (props) => {
  return props.asset && <Image {...props} />;
};

const slideVariants = {
  enter: (direction) => ({
    x: direction > 0 ? 400 : -400,
    opacity: 0,
  }),
  center: {
    x: 0,
    opacity: 1,
  },
  exit: (direction) => ({
    x: direction > 0 ? -400 : 400,
    opacity: 0,
  }),
};

export const ImageCarousel = ({ images }) => {
  const [[pageIndex, direction], setPageIndex] = useState([0, 0]);

  const wrappedIndex = wrap(0, images.length, pageIndex);

  const paginate = (direction) => {
    setPageIndex([pageIndex + direction, direction]);
  };

  return (
    <div>
      <div className='aspect-w-8 aspect-h-9 relative'>
        <AnimatePresence initial={false} custom={direction}>
          <motion.div
            key={pageIndex}
            custom={direction}
            drag='x'
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
              if (offset.x > 200) {
                paginate(-1);
              } else if (offset.x < -200) {
                paginate(1);
              }
            }}
            variants={slideVariants}
            initial='enter'
            animate='center'
            exit='exit'
            transition={{
              duration: 0.3,
            }}
            className='absolute h-full w-full top-0 left-0'
          >
            <CarouselSlide
              {...images[wrappedIndex]}
              key={pageIndex}
              className='h-full w-full top-0 left-0 absolute object-cover pointer-events-none'
            />
          </motion.div>
        </AnimatePresence>
      </div>
      {images.length > 1 && (
        <nav className='flex items-center justify-center mt-10'>
          {images.map((item, index) => {
            const isActive = index === pageIndex;
            return (
              <button
                aria-label='Slide button'
                onClick={() => setPageIndex([index, pageIndex > index ? 0 : 1])}
                className={`rounded-full mx-1 transition-all outline-none focus:outline-none ${
                  isActive ? 'bg-purple h-4 w-4' : 'bg-gray-300 h-2 w-2'
                }`}
              />
            );
          })}
        </nav>
      )}
    </div>
  );
};
