import React from 'react';

// Sanity Config
import BlockContent from '@sanity/block-content-to-react';

// Block Components
import { ImageModule, VideoModule } from '../modules';
import { PageButton, PathButton } from '../ui/links';

const BlockHeading = (props) => {
  switch (props.style) {
    case 'h1':
      return (
        <h1 className='font-display font-black leading-loose'>
          {props.children}
        </h1>
      );
    case 'h2':
      return <h2 className='font-display font-black'>{props.children}</h2>;
    case 'h3':
      return <h3 className='font-display font-black'>{props.children}</h3>;
    case 'h4':
      return <h4 className='font-display font-black'>{props.children}</h4>;
    case 'h5':
      return <h5 className='font-display font-black'>{props.children}</h5>;
    case 'h6':
      return <h6 className='font-display font-black'>{props.children}</h6>;
    default:
      return null;
  }
};

const textColor = (props) => {
  const { hex } = props.mark;
  return <span style={{ color: hex }}>{props.children}</span>;
};

const BlockRenderer = (props) => {
  const { style = 'normal' } = props.node;

  if (/^h\d/.test(style)) {
    return <BlockHeading style={style}>{props.children}</BlockHeading>;
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block(props);
};

const link = (props) => {
  return (
    <a
      href={props.mark.href}
      target='_blank'
      rel='noreferrer'
      className='underline'
    >
      {props.children}
    </a>
  );
};

export const Serializers = {
  types: {
    block: BlockRenderer,
    imageModule: ({ node }) => <ImageModule {...node} />,
    videoModule: ({ node }) => <VideoModule {...node} />,
    pageLink: ({ node }) => <PageButton {...node} />,
    pathLink: ({ node }) => <PathButton {...node} />,
    pageAnchorLink: ({ node }) => (
      <a href={`/${node?.page?.slug?.current}#${node?.anchor?.current}`}>
        {node.linkText}
      </a>
    ),
  },
  marks: { textColor, link },
};
