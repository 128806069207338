import React from 'react';
import Image from 'gatsby-plugin-sanity-image';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { motion } from 'framer-motion';

// Components
import { PortableTextBlock } from '../../sanity/portableTextBlock';
import { HoverButton } from '../../ui';
import { BannerLogo } from '../../ui';

// Animation Variants
const logoWrapper = {
  closed: {
    opacity: 0,
    x: 30,
    transition: {
      duration: 0.5,
      delay: 0.4,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
  open: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.7,
      delay: 1,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
};

const copyWrapper = {
  closed: {
    opacity: 0,
    y: 30,
    transition: {
      duration: 0.5,
      delay: 0.4,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
  open: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.7,
      delay: 0.5,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
};

const HomepageBanner = ({
  image,
  title,
  subtitle,
  buttonText,
  _rawTextBlock,
}) => {
  let bannerTitle = title.split(' ');

  return (
    <>
      <div className='overflow-x-hidden grid grid-cols-14 grid-rows-home-banner gap-y-10 lg:gap-y-0 relative'>
        {/* Grid Image */}
        {image && (
          <div className='col-start-1 col-end-15 row-start-1 row-end-3 bg-black'>
            <Image
              className='h-screen md:h-full lg:h-screen w-full object-cover opacity-50 lg:opacity-80'
              {...image}
            />
          </div>
        )}
        {/* Grid Text */}
        <motion.div
          variants={copyWrapper}
          initial='closed'
          animate='open'
          exit='closed'
          className='col-start-2 col-end-14 lg:col-start-1 lg:pl-20 3xl:col-start-2 3xl:pl-0 row-start-2 z-40 md:row-end-2 md:col-end-8 lg:col-end-9 lg:mt-20 xl:mt-20 2xl:mt-28 3xl:mt-52'
        >
          {title && (
            <h1
              style={{ lineHeight: '1.1' }}
              className='text-purple text-5xl lg:text-6xl xl:text-7xl 3xl:text-8xl mb-4 3xl:mb-8 font-bold'
            >
              {bannerTitle[0]}
              <br />
              {bannerTitle[1]} {bannerTitle[2]}
            </h1>
          )}
          <h4 className='text-white leading-loose xl:text-2xl mb-4 max-w-xl'>
            {subtitle && subtitle}
          </h4>
          {buttonText && (
            <AnchorLink href='#introText'>
              <HoverButton color={'white'}>{buttonText}</HoverButton>
            </AnchorLink>
          )}
        </motion.div>
        {/* Grid Logo */}
        <motion.div
          variants={logoWrapper}
          initial='closed'
          animate='open'
          exit='closed'
          className='hidden md:flex justify-items-end absolute right-0 top-0 h-full w-1/2  '
        >
          <BannerLogo className='w-full h-full mr-0' />
        </motion.div>
        {/* Intro Text */}
        <div
          id='introText'
          className='col-start-2 col-end-14 row-start-3 md:col-end-7 lg:pt-28'
        >
          {_rawTextBlock && (
            <PortableTextBlock
              text={_rawTextBlock}
              className='prose lg:prose-xl'
            />
          )}
        </div>
      </div>
    </>
  );
};

export default HomepageBanner;
