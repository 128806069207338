import React from 'react';

import { BlockWrapper } from '../blockWrapper';

function formatBytes(a, b = 2) {
  if (0 === a) return '0 Bytes';
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));
  return (
    parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
    ' ' +
    ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
  );
}

const SizeFormatted = ({ value }) => {
  const formatted = formatBytes(value.size);
  return <>{formatted}</>;
};

const DocumentRow = ({ title, file }) => {
  return (
    file && (
      <a
        href={`${file.asset.url}`}
        target='_blank'
        rel='noreferrer'
        className='bg-white text-dark hover:bg-purple hover:text-white cursor-pointer p-4 rounded-lg border border-gray-200 mb-4 flex flex-wrap'
      >
        {title && (
          <div className='font-bold w-full md:w-3/4 xl:text-xl'>{title}</div>
        )}
        <div className='uppercase flex justify-between md:justify-around w-full md:w-1/4'>
          <span className=''>{file.asset.extension}</span>
          <span>
            <SizeFormatted value={file.asset} />
          </span>
          <svg
            className='self-end'
            width='22'
            height='22'
            viewBox='0 0 22 22'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g clip-path='url(#clip0)'>
              <path
                className='fill-current'
                d='M21.2434 20.4188H0.824624C0.412124 20.4188 0.0683741 20.7625 0.0683741 21.175C-0.000375863 21.6563 0.343374 22 0.755874 22H21.1746C21.5871 22 21.9309 21.6563 21.9309 21.2438C21.9996 20.7625 21.6559 20.4188 21.2434 20.4188Z'
              />
              <path
                className='fill-current'
                d='M10.4498 17.05C10.5186 17.1187 10.5186 17.1187 10.4498 17.05L10.5186 17.1187C10.5186 17.1187 10.5186 17.1187 10.5873 17.1187C10.5873 17.1187 10.5873 17.1187 10.6561 17.1187C10.6561 17.1187 10.6561 17.1187 10.7248 17.1187C10.7248 17.1187 10.7248 17.1187 10.7936 17.1187C10.7936 17.1187 10.7936 17.1187 10.8623 17.1187H10.9311H10.9998C10.9998 17.1187 10.9998 17.1187 11.0686 17.1187H11.1373C11.1373 17.1187 11.1373 17.1187 11.2061 17.1187H11.2748C11.2748 17.1187 11.2748 17.1187 11.3436 17.1187C11.3436 17.1187 11.4123 17.1187 11.4123 17.05C11.4123 17.05 11.4123 17.05 11.4811 17.05L17.7373 10.7937C17.8748 10.6562 17.9436 10.45 17.9436 10.2437C17.9436 9.83125 17.5998 9.4875 17.1873 9.4875C16.9811 9.4875 16.7748 9.55625 16.6373 9.69375L11.6873 14.6437V0.825C11.7561 0.34375 11.4123 0 10.9998 0C10.5873 0 10.2436 0.34375 10.2436 0.75625V14.575L5.29355 9.625C5.15605 9.4875 4.9498 9.41875 4.74355 9.41875C4.33105 9.41875 3.9873 9.7625 3.9873 10.175C3.9873 10.3812 4.05605 10.5875 4.19355 10.725L10.4498 17.05Z'
              />
            </g>
            <defs>
              <clipPath id='clip0'>
                <rect className='fill-current' width='22' height='22' />
              </clipPath>
            </defs>
          </svg>
        </div>
      </a>
    )
  );
};
const Documents = ({ title, blockConfig, items }) => {
  return (
    <BlockWrapper className='px-gutter' {...blockConfig}>
      <section className='w-full max-w-4xl mx-auto'>
        {title && (
          <h3 className='font-bold text-2xl lg:text-4xl mb-4'>{title}</h3>
        )}
        {items.map((item) => (
          <DocumentRow {...item} />
        ))}
      </section>
    </BlockWrapper>
  );
};

export default Documents;
