import React from 'react';
import { useLocation } from '@reach/router';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { PortableTextBlock } from '../sanity/portableTextBlock';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export const Contact = ({ className, _rawSuccessText }) => {
  const { pathname } = useLocation();

  const itemWrapperClasses = 'relative col-span-1';

  const inputClasses =
    'block rounded-none shadow-none w-full my-2 border-b-2 border-dark pt-6 pb-2 outline-remove bg-transparent text-inherit placeholder-current';

  const errorClasses =
    'tracking-wide text-sm my-2 text-left absolute top-0 left-0';

  return (
    <div>
      <Formik
        initialValues={{
          fullName: '',
          contactMethod: 'Email',
          email: '',
          phone: '',
          company: '',
          message: '',
        }}
        validate={(values) => {
          const errors = {};
          if (!values.fullName) {
            errors.fullName = 'Full Name is Required';
          }
          if (values.contactMethod === 'Email') {
            if (!values.email) {
              errors.email = 'Email address is Required';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Invalid email address';
            }
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, setStatus }) => {
          fetch(pathname || '/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
              'form-name': `contact`,
              ...values,
            }),
          })
            .then(() => {
              setSubmitting(false);
              setStatus('success');
            })
            .catch((error) => alert(error));
        }}
      >
        {({
          onChange,
          isSubmitting,
          status,
          values,
          /* and other goodies */
        }) => (
          <Form
            className={`w-full relative ${className}`}
            name='contact'
            data-netlify='true'
            data-netlify-honeypot='bot-field'
            subject='Website Enquiry'
          >
            {/* Hidden Fields for netlify */}
            <input type='hidden' name='contact' value='contact' />
            <p hidden>
              <label>
                <input name='bot-field' onChange={onChange} />
              </label>
            </p>
            <>
              <div
                className={`prose lg:prose-xl text-center transition-all delay-300 duration-300 absolute top-0 left-0 right-0 z-10 ${
                  status === 'success'
                    ? 'opacity-100 pointer-events-all'
                    : 'opacity-0 pointer-events-none'
                }`}
              >
                <PortableTextBlock text={_rawSuccessText} />
              </div>
              <div
                className={`grid gap-x-12 grid-cols-1 font-body text-medium transition-all duration-300 ${
                  status === 'success' && 'opacity-0 pointer-events-none'
                }`}
              >
                <div className={itemWrapperClasses}>
                  <Field
                    type='text'
                    name='fullName'
                    className={inputClasses}
                    placeholder='Full Name'
                  />
                  <ErrorMessage
                    name='fullName'
                    className={errorClasses}
                    component='div'
                  />
                </div>
                <div className={itemWrapperClasses}>
                  <Field
                    type='text'
                    name='company'
                    className={inputClasses}
                    placeholder='Company'
                  />
                </div>
                <div className={itemWrapperClasses}>
                  <Field
                    type='email'
                    name='email'
                    className={inputClasses}
                    placeholder='Email Address'
                  />
                  <ErrorMessage
                    name='email'
                    className={errorClasses}
                    component='div'
                  />
                </div>
                <div className={itemWrapperClasses}>
                  <Field
                    type='text'
                    name='phone'
                    className={inputClasses}
                    placeholder='Phone Number'
                  />
                </div>
                <div className={itemWrapperClasses}>
                  <Field
                    type='text'
                    name='message'
                    className={inputClasses}
                    placeholder='Message'
                  />
                </div>
                <div className={`flex justify-end mt-4 ${itemWrapperClasses}`}>
                  <button
                    className='mt-8 block w-full border border-white rounded-full submit font-bold py-4 transition-all duration-200 hover:bg-white hover:text-dark'
                    type='submit'
                  >
                    Submit
                  </button>
                </div>
              </div>
            </>
          </Form>
        )}
      </Formik>
    </div>
  );
};
