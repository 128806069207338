import React from 'react';

import { PageLink, NavPathLink } from '../ui';

export const NavLink = ({ _type, ...rest }) => {
  const LinkComponent = () => {
    switch (_type) {
      case 'pageLink':
        return <PageLink {...rest} />;
      case 'pathLink':
        return <NavPathLink {...rest} />;

      default:
        return null;
    }
  };
  return <LinkComponent />;
};
