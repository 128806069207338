import React from 'react';
import { motion } from 'framer-motion';

const pathOne = {
  hidden: {
    opacity: 0,
    scale: 0.7,
  },
  visible: {
    opacity: 1,
    scale: 1,
  },
};

export const BannerLogo = ({ className }) => {
  return (
    <svg
      className={className}
      width='669'
      height='1222'
      viewBox='0 0 669 1222'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <motion.path
        variants={pathOne}
        initial='hidden'
        animate='visible'
        transition={{
          type: 'spring',
          duration: 1.5,
          bounce: 0.35,
          delay: 1.2,
        }}
        d='M601.148 700.992C662.328 700.992 711.923 651.689 711.923 590.87C711.923 530.051 662.328 480.748 601.148 480.748C539.969 480.748 490.373 530.051 490.373 590.87C490.373 651.689 539.969 700.992 601.148 700.992Z'
        fill='#632AFE'
      />
      <motion.path
        variants={pathOne}
        initial='hidden'
        animate='visible'
        transition={{
          type: 'spring',
          duration: 1.5,
          bounce: 0.35,
          delay: 1.3,
        }}
        d='M377.39 465.433C432.132 465.433 476.51 421.269 476.51 366.792C476.51 312.314 432.132 268.15 377.39 268.15C322.648 268.15 278.27 312.314 278.27 366.792C278.27 421.269 322.648 465.433 377.39 465.433Z'
        fill='#632AFE'
      />
      <motion.path
        variants={pathOne}
        initial='hidden'
        animate='visible'
        transition={{
          type: 'spring',
          duration: 1.5,
          bounce: 0.35,
          delay: 1.2,
        }}
        d='M127.994 1222C198.383 1222 255.445 1165.27 255.445 1095.3C255.445 1025.33 198.383 968.601 127.994 968.601C57.6046 968.601 0.542908 1025.33 0.542908 1095.3C0.542908 1165.27 57.6046 1222 127.994 1222Z'
        fill='#632AFE'
      />
      <motion.path
        variants={pathOne}
        initial='hidden'
        animate='visible'
        transition={{
          type: 'spring',
          duration: 1.5,
          bounce: 0.35,
          delay: 1.4,
        }}
        d='M363.655 945.156C426.15 945.156 476.812 894.792 476.812 832.666C476.812 770.539 426.15 720.175 363.655 720.175C301.16 720.175 250.497 770.539 250.497 832.666C250.497 894.792 301.16 945.156 363.655 945.156Z'
        fill='#632AFE'
      />
      <motion.path
        variants={pathOne}
        initial='hidden'
        animate='visible'
        transition={{
          type: 'spring',
          duration: 1.5,
          bounce: 0.35,
          delay: 1.5,
        }}
        d='M582.574 1054.32C608.887 1054.32 630.219 1033.12 630.219 1006.96C630.219 980.801 608.887 959.596 582.574 959.596C556.26 959.596 534.928 980.801 534.928 1006.96C534.928 1033.12 556.26 1054.32 582.574 1054.32Z'
        fill='#632AFE'
      />
      <motion.path
        variants={pathOne}
        initial='hidden'
        animate='visible'
        transition={{
          type: 'spring',
          duration: 1.5,
          bounce: 0.35,
          delay: 1.4,
        }}
        d='M614.416 181.959C631.87 181.959 646.019 167.81 646.019 150.356C646.019 132.902 631.87 118.752 614.416 118.752C596.962 118.752 582.812 132.902 582.812 150.356C582.812 167.81 596.962 181.959 614.416 181.959Z'
        fill='#632AFE'
      />
      <motion.path
        variants={pathOne}
        initial='hidden'
        animate='visible'
        transition={{
          type: 'spring',
          duration: 1.5,
          bounce: 0.35,
          delay: 1.2,
        }}
        d='M152.092 207.219C181.695 207.219 205.693 183.363 205.693 153.934C205.693 124.506 181.695 100.649 152.092 100.649C122.489 100.649 98.4915 124.506 98.4915 153.934C98.4915 183.363 122.489 207.219 152.092 207.219Z'
        fill='#632AFE'
      />
    </svg>
  );
};
