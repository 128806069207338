import React from 'react';
import ReactPlayer from 'react-player';

import Image from 'gatsby-plugin-sanity-image';
import { motion } from 'framer-motion';

const StandardPageBanner = ({
  className,
  title,
  date,
  caption,
  icon,
  animation,
}) => {
  return (
    <div className='pt-32 md:pt-40 lg:pt-48 xl:pt-56 2xl:pt-64 px-gutter text-center md:px-0 md:w-4/5 max-w-3xl mx-auto'>
      {icon && !animation && (
        <Image
          style={{ maxWidth: '175px' }}
          className='w-16 md:w-28 lg:w-full mx-auto mb-6'
          {...icon}
        />
      )}
      <div className='w-64 md:w-96 mx-auto mb-6'>
        {animation && (
          <ReactPlayer
            url={animation}
            playsinline
            playing
            width='100%'
            height='auto'
          />
        )}
      </div>
      {date && <div className='font-bold uppercase tracking-wide'>{date}</div>}
      {title && (
        <motion.h1
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
          transition={{
            ease: 'easeInOut',
            duration: 0.4,
          }}
          style={{ lineHeight: 1.3 }}
          className={`text-4xl lg:text-6xl font-bold ${className}`}
        >
          {title}
        </motion.h1>
      )}
      {caption && (
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
          transition={{
            ease: 'easeInOut',
            duration: 0.3,
            delay: 0.1,
          }}
          className='text-base lg:text-xl leading-loose mt-2 lg:mt-4'
        >
          {caption}
        </motion.p>
      )}
    </div>
  );
};

export default StandardPageBanner;
