import React from 'react';
import Image from 'gatsby-plugin-sanity-image';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';

// Hooks
import { useSanityPath } from '../../../hooks';

// Components
import { BlockWrapper } from '../blockWrapper';
import { PortableTextBlock } from '../../sanity/portableTextBlock';
import { ImageCarousel } from '../../carousels';

// Animation
const aniVars = {
  visible: {
    y: '0px',
  },
  hidden: {
    y: '25px',
  },
};

const ImageText = ({ _rawText, layout, images, blockConfig }) => {
  var imgCol = '';
  var textCol = '';
  if (layout === 'right') {
    textCol = `lg:pr-8 xl:pr-20 lg:col-start-2 lg:col-end-8`;
    imgCol = `lg:col-start-8`;
  } else {
    textCol = `lg:pl-8 xl:pl-20 lg:col-start-8`;
    imgCol = `lg:col-end-8`;
  }
  return (
    <BlockWrapper className='grid grid-cols-14' {...blockConfig}>
      <motion.div
        variants={aniVars}
        transition={{
          type: 'ease-out',
          damping: 10,
          duration: 0.6,
        }}
        className={`col-start-2 col-end-14 lg:row-start-1 ${imgCol}`}
      >
        <ImageCarousel images={images} />
      </motion.div>
      <div
        className={`mt-8 lg:mt-0 prose lg:prose-xl max-w-none lg:row-start-1 col-start-2 col-end-14 self-start xl:self-center ${textCol}`}
      >
        {_rawText && <PortableTextBlock text={_rawText} />}
      </div>
    </BlockWrapper>
  );
};

export default ImageText;
