import React, { useState, useCallback, useEffect } from 'react';
import { BlockWrapper } from '../blockWrapper';
import { PortableTextBlock } from '../../sanity/portableTextBlock';

import ReactMapGL, { Marker, FlyToInterpolator } from 'react-map-gl';

const Map = ({ caption, _rawText, blockConfig, items }) => {
  const [index, setIndex] = useState(0);

  const [viewport, setViewport] = useState({
    zoom: 15,
    bearing: 0,
    pitch: 0,
    longitude: items[index].location.lng,
    latitude: items[index].location.lat,
  });

  const handleViewportChange = useCallback((newViewport) => {
    setViewport(newViewport);
  }, []);

  useEffect(() => {
    setViewport((prev) => {
      return {
        ...prev,
        longitude: items[index].location.lng,
        latitude: items[index].location.lat,
        zoom: 15,
        transitionDuration: 2000,
        transitionInterpolator: new FlyToInterpolator(),
      };
    });
  }, [index, items, setViewport]);

  return (
    <BlockWrapper {...blockConfig} className='grid grid-cols-14'>
      <div className='row-start-1 col-start-2 col-end-14 md:col-end-6 md:pr-2 max-w-none'>
        <PortableTextBlock text={_rawText} />
        {items.map((item, i) => {
          const { title, _rawText } = item;
          return (
            <article className='mt-8'>
              {title && <h5 className='font-bold mb-2'>{title}</h5>}
              <PortableTextBlock text={_rawText} />
              <button
                aria-label={`Move map to ${title}`}
                className='cursor-pointer mt-2 underline'
                onClick={() => setIndex(i)}
              >
                View map
              </button>
            </article>
          );
        })}
      </div>
      <div className='row-start-2 md:row-start-1 col-start-2 md:col-start-6 col-end-14 mt-6 md:mt-0'>
        <ReactMapGL
          {...viewport}
          width='100%'
          height='50vh'
          mapboxApiAccessToken='pk.eyJ1IjoiYWxhc3RhaXJsYW5ncmlkZ2UiLCJhIjoiY2ttb2Jjem0yMDBiOTJwcDJ5aWI3OXJxNiJ9.8Bi61wBBp4gMQSZfldUJ6A'
          onViewportChange={handleViewportChange}
        >
          {items.map(({ location: { lat, lng } }, itemIndex) => {
            return (
              <Marker
                className='w-8 h-8 rounded-full bg-purple'
                latitude={lat}
                longitude={lng}
                active={itemIndex === index}
              ></Marker>
            );
          })}
        </ReactMapGL>
        {caption && <p className='text-sm mt-8'>{caption}</p>}
      </div>
    </BlockWrapper>
  );
};

export default Map;
