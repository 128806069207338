import React from 'react';

export const HoverButton = ({
  noHover,
  className,
  right,
  color,
  align,
  children,
}) => {
  return (
    <div
      className={`${className} w-auto cursor-pointer ${
        !noHover && 'group'
      } inline-block relative h-8 ${color === 'white' && 'text-white'}`}
    >
      <span
        className={`absolute ${
          right ? 'right-0' : 'left-0'
        } top-0 bottom-0 z-10 transition-all duration-300 w-8 h-8 rounded-full bg-purple lg:group-hover:w-full ${
          align === 'right' && 'flex justify-end'
        }`}
      ></span>
      <span
        className={`absolute z-20 top-1/2 left-0 transform -translate-y-2/4 w-full ${
          !noHover && 'transition-all duration-300'
        } text-center font-bold uppercase lg:group-hover:text-white tracking-wider`}
      >
        {children}
      </span>
      <span className='h-8 flex items-center z-20 px-16 opacity-0'>
        {children}
      </span>
    </div>
  );
};
