import React from 'react';

import BlockContent from '@sanity/block-content-to-react';
import { Serializers } from './serializers.js';

export const PortableTextBlock = ({ className, text, ...props }) => {
  return (
    <BlockContent
      className={`block-content ${className}`}
      blocks={text}
      serializers={Serializers}
      {...props}
    />
  );
};
